// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown_dropdown__ikpfo {\n  text-align: right;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://./src/components/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA;EACA,iBAAiB;EACf,WAAW,EAAA","sourcesContent":[".dropdown {\r\ntext-align: right;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "Dropdown_dropdown__ikpfo"
};
export default ___CSS_LOADER_EXPORT___;
