import React, {useEffect, useState} from 'react';

// libs
import Box from "@mui/material/Box";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {format} from "date-fns";
import CheckIcon from '@mui/icons-material/Check';
import {FormControl, Grid, InputLabel, ListItemText} from "@mui/material";

// components
import {getRecallsFiltered} from "../../api";

const dropdown = ['Past Month', 'Past 3 Months', 'Past 6 Months', 'Past Year', 'Past 3 Years', 'Past 5 Years', 'Past 10 Years', 'Past 15 Years', 'Past 20 Years', 'All-Time', 'Custom Dates'];

const DropdownCustomDate = ({
                                setData,
                                setOrder,
                                setOrderBy,
                                tabValue,
                                setRecallDropdownData,
                                recallDropdownData,
                                valueSelect,
                                setValueSelect,
                                dateRangeValue,
                                setDateRangeValue
                            }) => {
    const [isCustomDate, setIsCustomDate] = useState(false);

    const handleChange = (event) => {
        setValueSelect(event.target.value);

        // set to default on change select
        if (tabValue === 0 || tabValue === 1) {
            setOrder('desc');
            setOrderBy('Total Recalls');
        }

        if (event.target.value !== 'Custom Dates') {
            let days = null; // Days you want to subtract
            if (event.target.value === 'Past Month') days = 30;
            if (event.target.value === 'Past 3 Months') days = 90;
            if (event.target.value === 'Past 6 Months') days = 182;
            if (event.target.value === 'Past Year') days = 365;
            if (event.target.value === 'Past 3 Years') days = 1096;
            if (event.target.value === 'Past 5 Years') days = 1826;
            if (event.target.value === 'Past 10 Years') days = 3653;
            if (event.target.value === 'Past 15 Years') days = 5479;
            if (event.target.value === 'Past 20 Years') days = 7305;
            if (event.target.value === 'All-Time') setRecallDropdownData({
                ...recallDropdownData,
                time: [null, format(new Date(), 'yyyy-MM-dd')]
            })

            if (days !== null) {
                let date = new Date();
                let last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
                let day = last.getDate();
                let month = last.getMonth() + 1;
                let year = last.getFullYear();

                const start_date = `${year}-${month}-${day}`;
                const end_date = format(new Date(), 'yyyy-MM-dd');
                setRecallDropdownData({...recallDropdownData, time: [start_date, end_date]})
            } else {
                setRecallDropdownData({...recallDropdownData, time: [null, null]})
            }
        }
    };

    const handleClose = () => {
        let start_date = dateRangeValue[0];
        let end_date = dateRangeValue[1];
        if (dateRangeValue[0] !== null) start_date = format(new Date(dateRangeValue[0]), 'yyyy-MM-dd');
        if (dateRangeValue[1] !== null) end_date = format(new Date(dateRangeValue[1]), 'yyyy-MM-dd');

        getRecallsFiltered(start_date, end_date, recallDropdownData.selectedRecallCause === 'Software Related Recalls' ? true : null, recallDropdownData.selectedRecallOem !== 'ALL MAKES' ? recallDropdownData.selectedRecallOem : null)
            .then(res => res.json())
            .then(res => setData(JSON.parse(res)))
            .catch(err => console.log(err));

        setRecallDropdownData({...recallDropdownData, time: [start_date, end_date]})
    }

    useEffect(() => {
        if (valueSelect === 'Custom Dates') {
            setIsCustomDate(true)
        } else {
            setIsCustomDate(false)
        }
    }, [valueSelect]);

    return (
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={12} sm='auto'>
                <FormControl fullWidth >
                    <InputLabel>Time Frame</InputLabel>
                    <Select label='Time Frame' value={valueSelect} onChange={handleChange} displayEmpty>
                        {dropdown.map(name => (
                            <MenuItem key={name} value={name}>
                                <CheckIcon/>
                                <ListItemText primary={name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {isCustomDate && <Grid item xs={12} sm='auto'>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    localeText={{ start: 'Start Date', end: 'End Date' }}
                >
                    <DateRangePicker
                       onClose={() => handleClose()}
                        value={dateRangeValue}
                        onChange={(newValue) => setDateRangeValue(newValue)}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField {...startProps} />
                                <Box sx={{mx: 2}}> to </Box>
                                <TextField {...endProps} />
                            </>
                        )}
                    />
                </LocalizationProvider>
            </Grid>}
        </Grid>
    );
}

export default DropdownCustomDate;
