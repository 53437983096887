import React, {useEffect, useState} from 'react';

// libs
import {isEmpty} from 'lodash';

// components
import EnhancedTable from "../Table/EnhancedTable";
import StackedBarChart from "../StackedBarChart/StackedBarChart";
import {getRecalls, getParentMakes, getRecallsFiltered} from "../../api";
import Dropdown from "../Dropdown/Dropdown";
import OemDropdowns from "../Dropdown/OemDropdowns";
import SimplePieChart from '../SimplePieChart/SimplePieChart';
import DropdownAllRecalls from "../DropdownAllRecalls/DropdownAllRecalls";
import {Loading} from "../Loading/Loading";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {format} from "date-fns";

// assets
import styles from './App.module.scss';
import logo from '../../images/logo.svg';

export const useStyles = makeStyles((theme) => ({
    header: {
        padding: '20px 32px', background: '#fff', marginBottom: theme.spacing(4),
    },
    box: {
        flex: '1 1 auto',
        margin: '8px',
        padding: '8px',
        [theme.breakpoints.up('md')]: {
            margin: '32px',
            padding: '20px',
        },
        marginBottom: theme.spacing(4),
        background: '#fff',
        borderRadius: '6px',
        boxShadow: '0 2px 1px -1px rgba(0, 0, 0, .20), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)',
        '& .MuiButtonBase-root': {
            fontWeight: 600,
        },
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(4),
        background: '#fff',
        borderRadius: '6px',
        boxShadow: '0 2px 1px -1px rgba(0, 0, 0, .20), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)',
    }
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (<Box sx={{px: 0, py: 3}} sm={{p: 3}}>
            {children}
        </Box>)}
    </div>);
}

const defaultData = {
    selectedRecallCause: 'All Recalls',
    selectedRecallOem: 'ALL MAKES',
    time: [format(new Date().setFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]
};

const App = () => {
    const classes = useStyles();

    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('Total Recalls');
    const [selectedDataSet, setSelectedDataSet] = useState('Graph Recalls');
    const [recallDropdownData, setRecallDropdownData] = useState(defaultData);
    const [makeData, setMakeData] = useState({});
    const [makeRows, setMakeRows] = useState([]);
    const [tabValue, setTabValue] = useState(0)
    const [selectedBar, setSelectedBar] = useState('');
    const [valueSelect, setValueSelect] = useState('Past Year');
    const [searchValue, setSearchValue] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState([null, null]);
    const [sortedRows, setSortedRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setData({});
        setLoading(true);
        if (tabValue === 0) {
            getRecalls(recallDropdownData.time[0], recallDropdownData.time[1])
                .then(res => res.json())
                .then(res => {
                    setData(JSON.parse(res));
                    setLoading(false);
                })
                .catch(err => console.log(err));
        } else if (tabValue === 1) {
            getRecalls(recallDropdownData.time[0], recallDropdownData.time[1], recallDropdownData.selectedRecallCause === 'Software Related Recalls' ? true : null)
                .then(res => res.json())
                .then(res => {
                    setData(JSON.parse(res));
                    setLoading(false);
                })
                .catch(err => console.log(err));
        } else if (tabValue === 2) {
            getRecallsFiltered(recallDropdownData.time[0], recallDropdownData.time[1], recallDropdownData.selectedRecallCause === 'Software Related Recalls' ? true : null, recallDropdownData.selectedRecallOem !== 'ALL MAKES' ? recallDropdownData.selectedRecallOem : null)
                .then(res => res.json())
                .then(res => {
                    setData(JSON.parse(res));
                    setLoading(false);
                })
                .catch(err => console.log(err));
            getParentMakes()
                .then(res => res.json())
                .then(res => setMakeData(JSON.parse(res)))
                .catch(err => console.log(err));
        }
        // eslint-disable-next-line
    }, [tabValue, recallDropdownData]);

    useEffect(() => {
        if (makeData) {
            let parents = makeData.parent_make;
            let make_array = []
            for (const key in parents) {
                make_array.push(parents[key])
            }
            setMakeRows(make_array)
        }
    }, [makeData]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        if (selectedBar) {
            setRecallDropdownData({...recallDropdownData, selectedRecallOem: selectedBar})
            setTabValue(2);
        }
        // eslint-disable-next-line
    }, [selectedBar]);

    useEffect(() => {
        setPage(1);
        setSearchValue('');
    }, [valueSelect, selectedDataSet, recallDropdownData])

    useEffect(() => {
        if (data) {
            if (data.parent_make) {
                const newDate = [];
                for (const property in data.parent_make) {
                    newDate.push({
                            parent_make: data['parent_make'][property],
                            'Total Recalls': data['n_recalls'][property],
                            'Software Related Recalls': data['n_recalls_software'][property],
                            '% Software Related Recalls': data['pct_software_recalls'][property],
                            'Other Recalls': data['n_recalls_other'][property],
                            logo: data['logo_file'][property].split('/')[1],
                            'Total Recalled Units': data["sum_involved_population_total"][property],
                            'Other Units': data["sum_involved_population_other"][property],
                            'Software Units': data["sum_involved_population_software"][property],
                            '% Software Related Units': data["pct_units_software"][property],
                            'Category': data["category"][property],
                        }
                    )
                }
                setRows(newDate)
            }
            // eslint-disable-next-line
        }
    }, [data]);


    return (
        <div className={styles.app}>
            <Box className={classes.header}>
                <a href='https://www.sibros.tech' target='_blank'><img className={styles.logo} src={logo} alt=""/></a>
            </Box>
            <Box className={classes.box}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs variant='scrollable' value={tabValue} onChange={handleTabChange} aria-label="tabs"
                          className={classes.tabs}>
                        <Tab label="Top OEMs by Recall" {...a11yProps(0)} />
                        <Tab label="ALL RECALLS" {...a11yProps(1)} />
                        <Tab label="RECALL CAUSE" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Box className={classes.wrapper}>
                        <Dropdown
                            setData={setData}
                            setPage={setPage}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            selectedDataSet={selectedDataSet}
                            setSelectedDataSet={setSelectedDataSet}
                            recallDropdownData={recallDropdownData}
                            setRecallDropdownData={setRecallDropdownData}
                            tabValue={tabValue}
                            valueSelect={valueSelect}
                            setValueSelect={setValueSelect}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}/>
                    </Box>
                    {loading ? <Loading/> : <Box>
                        {!isEmpty(data.parent_make) ? <><Box mb={4}>
                            <StackedBarChart
                                time={recallDropdownData.time}
                                rows={sortedRows}
                                selectedDataSet={selectedDataSet}
                                order={order}
                                orderBy={orderBy}
                                setSelectedBar={setSelectedBar}
                                tabValue={tabValue}
                                recallDropdownData={recallDropdownData}
                                setOrderBy={setOrderBy}
                                setOrder={setOrder}/>
                        </Box>
                            <EnhancedTable
                                setPage={setPage}
                                page={page}
                                rows={rows}
                                setOrder={setOrder}
                                order={order}
                                setOrderBy={setOrderBy}
                                orderBy={orderBy}
                                tabValue={tabValue}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setSelectedBar={setSelectedBar}
                                sortedRows={sortedRows}
                                setSortedRows={setSortedRows}
                                clickable/>
                        </> : <Typography align='center'>Not found</Typography>}
                    </Box>}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Box className={classes.wrapper}>
                        <DropdownAllRecalls
                            setData={setData}
                            setPage={setPage}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            selectedDataSet={selectedDataSet}
                            setSelectedDataSet={setSelectedDataSet}
                            recallDropdownData={recallDropdownData}
                            setRecallDropdownData={setRecallDropdownData}
                            tabValue={tabValue}
                            valueSelect={valueSelect}
                            setValueSelect={setValueSelect}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}/>
                    </Box>
                    {loading ? <Loading/> : <Box>
                        {!isEmpty(data.parent_make) ? <>
                            <Box mb={4}>
                                <StackedBarChart
                                    tabValue={tabValue}
                                    time={recallDropdownData.time}
                                    rows={sortedRows}
                                    selectedDataSet={selectedDataSet}
                                    order={order}
                                    orderBy={orderBy}
                                    setSelectedBar={setSelectedBar}
                                    recallDropdownData={recallDropdownData}
                                    setOrderBy={setOrderBy}
                                    setOrder={setOrder}/>
                            </Box>
                            <EnhancedTable
                                setPage={setPage}
                                page={page}
                                rows={rows}
                                setOrder={setOrder}
                                order={order}
                                setOrderBy={setOrderBy}
                                orderBy={orderBy}
                                tabValue={tabValue}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setSelectedBar={setSelectedBar}
                                sortedRows={sortedRows}
                                setSortedRows={setSortedRows}
                                clickable/>
                        </> : <Typography align='center'>Not found</Typography>}
                    </Box>}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Box className={classes.wrapper}>
                        <OemDropdowns
                            setData={setData}
                            selectedRecallOem={recallDropdownData.selectedRecallOem}
                            makeRows={makeRows}
                            recallDropdownData={recallDropdownData}
                            setRecallDropdownData={setRecallDropdownData}
                            setPage={setPage}
                            tabValue={tabValue}
                            valueSelect={valueSelect}
                            setValueSelect={setValueSelect}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}/>
                    </Box>
                    {loading ? <Loading/> : <Box>
                        {!isEmpty(data.parent_make) ? <>
                            <Box mb={4}>
                                <SimplePieChart
                                    selectedRecallOem={recallDropdownData.selectedRecallOem}
                                    recallDropdownData={recallDropdownData}
                                    tabValue={tabValue}
                                    rows={rows}/>
                            </Box>
                            <EnhancedTable
                                setPage={setPage}
                                page={page}
                                rows={rows}
                                setOrder={setOrder}
                                order={order}
                                setOrderBy={setOrderBy}
                                orderBy={orderBy}
                                tabValue={tabValue}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                sortedRows={sortedRows}
                                setSortedRows={setSortedRows}/>
                        </> : <Typography align='center'>No Recalls Found</Typography>}
                    </Box>}
                </TabPanel>
            </Box>
            <Box my={4} maxWidth='900px' mx='auto' px='1rem'>
                <Typography fontSize='14px' align='center'>
                    Data sourced from the National Highway Traffic Safety Administration (NHTSA). Data shown herein may
                    differ from NHTSA reporting as we group OEMs by parent company and refresh our datasets on a weekly
                    basis, in addition to omissions in data from NHTSA data feeds.
                </Typography>
            </Box>
        </div>
    );
}

export default App;
