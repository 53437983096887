// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StackedBarChart_stackedBarChart__ICDlF {\n  width: 100%; }\n  .StackedBarChart_stackedBarChart__ICDlF .recharts-bar-rectangle {\n    cursor: pointer; }\n  .StackedBarChart_stackedBarChart__ICDlF .StackedBarChart_legend__nJ2i8 {\n    margin-top: 10px;\n    margin-left: auto;\n    width: calc(100% - 60px);\n    display: flex;\n    align-items: center;\n    justify-content: space-around; }\n    .StackedBarChart_stackedBarChart__ICDlF .StackedBarChart_legend__nJ2i8 img {\n      max-width: 7%;\n      max-height: 32px;\n      object-fit: contain; }\n\n@media screen and (min-width: 992px) {\n  .StackedBarChart_stackedBarChart__ICDlF .StackedBarChart_title__KxuQ- br {\n    display: none; } }\n", "",{"version":3,"sources":["webpack://./src/components/StackedBarChart/StackedBarChart.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;EADb;IAKM,eAAe,EAAA;EALrB;IAUI,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,6BAA6B,EAAA;IAfjC;MAkBM,aAAa;MACb,gBAAgB;MAChB,mBAAmB,EAAA;;AAKzB;EACE;IAGM,aAAa,EAAA,EACd","sourcesContent":[".stackedBarChart {\r\n  width: 100%;\r\n\r\n  :global {\r\n    .recharts-bar-rectangle {\r\n      cursor: pointer;\r\n    }\r\n  }\r\n\r\n  .legend {\r\n    margin-top: 10px;\r\n    margin-left: auto;\r\n    width: calc(100% - 60px);\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n\r\n    img {\r\n      max-width: 7%;\r\n      max-height: 32px;\r\n      object-fit: contain;\r\n    }\r\n  }\r\n}\r\n\r\n@media screen and (min-width: 992px) {\r\n  .stackedBarChart {\r\n    .title {\r\n      br {\r\n        display: none;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stackedBarChart": "StackedBarChart_stackedBarChart__ICDlF",
	"legend": "StackedBarChart_legend__nJ2i8",
	"title": "StackedBarChart_title__KxuQ-"
};
export default ___CSS_LOADER_EXPORT___;
