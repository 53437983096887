import React from 'react';

// libs
import {Box, Typography} from "@mui/material";

export const Loading = () => {
    return (
        <Box mt={2} mb={2}>
            <Typography component='h2'>Loading...</Typography>
        </Box>
    )
}