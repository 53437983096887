import React, {useEffect, useState} from 'react';

// libs
import {
    BarChart,
    Bar,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    XAxis,
    PieChart,
    Pie, Cell
} from "recharts";
import {Box} from "@mui/material";
import {Typography} from "@material-ui/core";
import {isEmpty} from "lodash";

// components
import {useWindowSize} from "../../utils/useWindowsSize";

// assets
import styles from './StackedBarChart.module.scss';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];
const RADIAN = Math.PI / 180;

const customLegend = ({payload}) => {
    return (
        <div>
            {
                payload.map((entry, index) => (
                    <div key={`item-${index}`} style={{display: 'flex', gap: 5, marginBottom: 10}}>
                        <span
                            style={{width: 30, backgroundColor: entry.color}}/><span>{entry.payload.parent_make}</span>
                    </div>
                ))
            }
        </div>
    );
};

const customLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent,}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const StackedBarChart = ({
                             time,
                             rows,
                             selectedDataSet,
                             order,
                             orderBy,
                             setSelectedBar,
                             tabValue,
                             recallDropdownData,
                             setOrderBy,
                             setOrder
                         }) => {
    const {width} = useWindowSize();

    const [chartBars, setChartBars] = useState([
        {fieldName: "Other Recalls", color: "#0788a5"},
        {fieldName: "Software Related Recalls", color: "#68d0e7"}
    ]);
    const [fieldName, setFieldName] = useState('Software Related Recalls');
    const [filteredRows, setFilteredRows] = useState(null)
    const [size, setSize] = useState({width: '100%', height: 600});

    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p>{payload[0].payload['parent_make']}</p>
                    {selectedDataSet === 'Graph Recalls' && <>
                        <p className="blue">{`Software Related Recalls: ${payload[0].payload['Software Related Recalls']}`}</p>
                        {(recallDropdownData.selectedRecallCause !== 'Software Related Recalls' || tabValue !== 1) &&
                            <p className="light-blue">{`Other Recalls: ${payload[0].payload['Other Recalls']}`}</p>}
                        {(recallDropdownData.selectedRecallCause !== 'Software Related Recalls' || tabValue !== 1) &&
                            <p>{`Total Recalls: ${payload[0].payload['Total Recalls']}`}</p>}}
                    </>}
                    {selectedDataSet === 'Graph Units' && <>
                        <p className="blue">{`Software Units: ${payload[0].payload['Software Units']}`}</p>
                        {(recallDropdownData.selectedRecallCause !== 'Software Related Recalls' || tabValue !== 1) &&
                            < p className="light-blue">{`Other Units : ${payload[0].payload['Other Units']}`}</p>}
                        {(recallDropdownData.selectedRecallCause !== 'Software Related Recalls' || tabValue !== 1) &&
                            <p>{`Total Recalled Units: ${payload[0].payload['Total Recalled Units']}`}</p>}
                    </>}
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        if (tabValue === 1) {
            const limit = 10;
            const data = rows.slice(0, limit);
            if (rows.length > limit) {
                const others_data = rows.slice(limit);
                let others_obj = {
                    "parent_make": 'Others',
                    "Other Recalls": 0,
                    "Other Units": 0,
                    "Total Recalled Units": 0,
                    "Total Recalls": 0,
                    'Software Related Recalls': 0,
                    'Software Units': 0,
                };
                for (let i = 0; i < others_data.length; i++) {
                    let obj = others_data[i];
                    others_obj["Other Recalls"] += obj["Other Recalls"]
                    others_obj["Other Units"] += obj["Other Units"]
                    others_obj["Total Recalled Units"] += obj["Total Recalled Units"]
                    others_obj["Total Recalls"] += obj["Total Recalls"]
                    others_obj["Software Related Recalls"] += obj["Software Related Recalls"]
                    others_obj["Software Units"] += obj["Software Units"]
                }
                data.push(others_obj);
            }
            setFilteredRows(data);
        } else {
            setFilteredRows(null);
        }
    }, [rows, tabValue])


    useEffect(() => {
        setOrder('desc')
        if (selectedDataSet === 'Graph Recalls') {
            setOrderBy('Total Recalls')
            setChartBars([
                {fieldName: "Other Recalls", color: "#0788a5"},
                {fieldName: "Software Related Recalls", color: "#68d0e7"}
            ])
        }
        if (selectedDataSet === 'Graph Units') {
            setOrderBy('Total Recalled Units')
            setChartBars([
                {fieldName: "Other Units", color: "#0788a5"},
                {fieldName: "Software Units", color: "#68d0e7"}
            ])
        }
        // eslint-disable-next-line
    }, [selectedDataSet]);

    useEffect(() => {
        if (selectedDataSet === 'Graph Recalls') {
            if (recallDropdownData.selectedRecallCause === 'Software Related Recalls') {
                setFieldName('Software Related Recalls');
            } else {
                setFieldName('Total Recalls');
            }
        } else if (selectedDataSet === 'Graph Units') {
            if (recallDropdownData.selectedRecallCause === 'Software Related Recalls') {
                setFieldName('Total Recalled Units');
            } else {
                setFieldName('Software Units');
            }
        }
    }, [recallDropdownData, selectedDataSet])


    useEffect(() => {
        if (width < 768) {
            const newSize2 = {width: '99%', height: 600};
            setTimeout(() => {
                const rechartsLegendWrapper = document.getElementsByClassName('recharts-legend-wrapper')[0];
                setSize({...newSize2, height: rechartsLegendWrapper.offsetHeight + window.innerWidth})
            }, 300)
        }
    }, [filteredRows]);

    return (
        <div className={styles.stackedBarChart}>
            {tabValue === 0 ? <>
                <Box mb={2}>
                    <Typography component='h3'>
                        <Box sx={{fontWeight: '600', mb: 6}} className={styles.title}>
                            Top 10 OEMs by Recall <br/>
                            from {time[0] ? new Date(time[0]).toLocaleDateString("en-US") : 'X'} to {time[1] ? new Date(time[1]).toLocaleDateString("en-US") : 'Y'}
                            <br/> sorted
                            by {orderBy} {order === 'desc' ? 'Descending' : 'Ascending'}
                        </Box>
                    </Typography>
                </Box>
                {!isEmpty(rows) && <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart width={500} height={300} data={rows.slice(0, 10)}
                              margin={{top: -40, right: 0, left: 0, bottom: 0}}>
                        <CartesianGrid strokeDasharray="#fff"/>
                        <YAxis/>
                        <XAxis dataKey='parent_make' hide/>
                        <Tooltip content={<CustomTooltip/>} cursor={{fill: 'transparent'}}/>
                        <Legend verticalAlign="top" align='right' height={36}/>
                        {chartBars?.map((bar, index) =>
                            <Bar
                                radius={[2, 2, 2, 2]}
                                key={index}
                                dataKey={bar.fieldName}
                                stackId="a"
                                barSize={50}
                                fill={bar.color}
                                onClick={(e) => setSelectedBar(e.payload.parent_make)}/>)}
                    </BarChart>
                </ResponsiveContainer>}
                <div className={styles.legend}>
                    {!isEmpty(rows) &&
                        rows.slice(0, 10).map((item, index) => (
                            <img key={index} src={`./logos/${item.logo}`} alt=""/>
                        ))}
                </div>
            </> : <Box maxWidth='1050px' mr='auto' ml='auto'>
                <Box mb={2}>
                    <Typography component='h3' align='center'>
                        <Box sx={{fontWeight: '600'}}>
                            All Recalls by Make
                            from {time[0] ? new Date(time[0]).toLocaleDateString("en-US") : 'X'} to {time[1] ? new Date(time[1]).toLocaleDateString("en-US") : 'Y'}
                        </Box>
                    </Typography>
                </Box>
                {filteredRows && <ResponsiveContainer width={size.width} height={size.height}>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={filteredRows}
                            labelLine={false}
                            fill="#8884d8"
                            dataKey={fieldName}
                            cx="50%"
                            cy="50%"
                            label={customLabel}>
                            {filteredRows.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend
                            content={customLegend}
                            layout={width > 768 ? "vertical" : "horizontal"}
                            verticalAlign={width > 768 ? "middle" : 'bottom'}
                            align="right"/>
                    </PieChart>
                </ResponsiveContainer>}
            </Box>}
        </div>
    );
}

export default StackedBarChart;
