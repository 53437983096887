// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_app__Z1SWl {\n  background: #eceeef;\n  min-height: 100%;\n  flex: 1 1 auto;\n  display: flex;\n  flex-direction: column; }\n  .App_app__Z1SWl .App_logo__Unxa2 {\n    max-width: 110px;\n    transition: .3s linear; }\n    .App_app__Z1SWl .App_logo__Unxa2:hover, .App_app__Z1SWl .App_logo__Unxa2:active {\n      opacity: .7; }\n\n@media screen and (max-width: 768px) {\n  .App_app__Z1SWl {\n    padding: 0; } }\n", "",{"version":3,"sources":["webpack://./src/components/App/App.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB,EAAA;EALxB;IAOI,gBAAgB;IAChB,sBAAsB,EAAA;IAR1B;MAUM,WAAW,EAAA;;AAKjB;EACE;IACE,UAAU,EAAA,EACX","sourcesContent":[".app {\r\n  background: #eceeef;\r\n  min-height: 100%;\r\n  flex: 1 1 auto;\r\n  display: flex;\r\n  flex-direction: column;\r\n  .logo {\r\n    max-width: 110px;\r\n    transition: .3s linear;\r\n    &:hover, &:active {\r\n      opacity: .7;\r\n    }\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .app {\r\n    padding: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "App_app__Z1SWl",
	"logo": "App_logo__Unxa2"
};
export default ___CSS_LOADER_EXPORT___;
