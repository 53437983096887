// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EnhancedTable_table__QAzT4 {\n  padding: 20px 0;\n  margin: 0 auto; }\n  .EnhancedTable_table__QAzT4 tbody th {\n    text-decoration: underline; }\n  .EnhancedTable_table__QAzT4 .MuiPaper-root {\n    box-shadow: none; }\n  .EnhancedTable_table__QAzT4 .Mui-selected {\n    background-color: #fff !important;\n    border-color: #68d0e7; }\n  .EnhancedTable_table__QAzT4.EnhancedTable_clickable__hTdWL tr {\n    cursor: pointer; }\n    .EnhancedTable_table__QAzT4.EnhancedTable_clickable__hTdWL tr:hover th, .EnhancedTable_table__QAzT4.EnhancedTable_clickable__hTdWL tr:active th {\n      text-decoration: none; }\n", "",{"version":3,"sources":["webpack://./src/components/Table/EnhancedTable.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc,EAAA;EAFhB;IAII,0BAA0B,EAAA;EAJ9B;IAQM,gBAAgB,EAAA;EARtB;IAWM,iCAAiC;IACjC,qBAAqB,EAAA;EAZ3B;IAiBM,eAAe,EAAA;IAjBrB;MAoBU,qBAAqB,EAAA","sourcesContent":[".table {\r\n  padding: 20px 0;\r\n  margin: 0 auto;\r\n  tbody th {\r\n    text-decoration: underline;\r\n  }\r\n  :global {\r\n    .MuiPaper-root {\r\n      box-shadow: none;\r\n    }\r\n    .Mui-selected {\r\n      background-color: #fff !important;\r\n      border-color: #68d0e7;\r\n    }\r\n  }\r\n  &.clickable {\r\n    tr {\r\n      cursor: pointer;\r\n      &:hover, &:active {\r\n        th {\r\n          text-decoration: none;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "EnhancedTable_table__QAzT4",
	"clickable": "EnhancedTable_clickable__hTdWL"
};
export default ___CSS_LOADER_EXPORT___;
