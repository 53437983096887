// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimplePieChart_simplePieChart__gIhDZ {\n  width: 100%;\n  max-width: 1050px;\n  margin: 0 auto; }\n  .SimplePieChart_simplePieChart__gIhDZ .recharts-wrapper {\n    margin: 0 auto; }\n", "",{"version":3,"sources":["webpack://./src/components/SimplePieChart/SimplePieChart.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc,EAAA;EAHhB;IAOM,cAAe,EAAA","sourcesContent":[".simplePieChart {\r\n  width: 100%;\r\n  max-width: 1050px;\r\n  margin: 0 auto;\r\n\r\n  :global {\r\n    .recharts-wrapper {\r\n      margin : 0 auto;\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simplePieChart": "SimplePieChart_simplePieChart__gIhDZ"
};
export default ___CSS_LOADER_EXPORT___;
